@import url(https://fonts.googleapis.com/css?family=Carter+One);
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
}

.fields {
	width: 100%;
}


